<template>
  <div class="comming-draws-wrapper"
       :class="{draw: $store.getters.drawInProgress}">
    <section class="comming-draws-next-event-wrapper left">
      <component :is="leftComponent"
                 :class="{active: leftComponentActive,
                 opacity: leftComponentOpacityActive}"></component>
    </section>
    <section class="comming-draws-sidebar-wrapper right">
      <component :is="rightComponent"
                 :class="{active: rightComponentActive,
                 opacity: rightComponentOpacityActive}"></component>
    </section>
  </div>
</template>

<script>

import EventBus from '@/store/EventBus';
import { workerPostMessage } from '@/utility/workerMessage';
import { first } from 'lodash';

export default {
  name: 'CommingDrawsWrapper',
  data() {
    return {
      remainingTime: '',
      leftComponent: null,
      leftComponentActive: false,
      leftComponentOpacityActive: false,
      rightComponent: null,
      rightComponentActive: false,
      rightComponentOpacityActive: false,
      callResultsInterval: null,
      viewConfig: {
        left: {
          1: 'NextEvent',
          2: 'NextEvent',
          3: 'NextEvent',
          4: 'NextEvent',
        },
        right: {
          1: 'Statistics',
          2: 'LastResultsView',
          3: 'NextSameEvents',
          4: 'BettingOffer',
        },
      },
    };
  },
  components: {
    LotteryDrawSidebar: () => import('./LotteryDrawSidebar'),
    LotteryDraw: () => import('./LotteryDraw'),
    WaitingForDraw: () => import('./WaitingForDraw'),
    LastResultsView: () => import('./LastResultsView'),
    Statistics: () => import('./Statistics'),
    BettingOffer: () => import('./BettingOffer'),
    NextSameEvents: () => import('./NextSameEvents'),
    NextEvent: () => import('./NextEvent'),
  },
  methods: {
    transitionView(type, component) {
      this[`${type}ComponentActive`] = false;
      if (!component) {
        return;
      }
      setTimeout(() => {
        this[`${type}ComponentOpacityActive`] = false;
      }, 330);
      setTimeout(() => {
        this[`${type}Component`] = component;
        setTimeout(() => {
          this[`${type}ComponentActive`] = true;
        }, 100);
        setTimeout(() => {
          this[`${type}ComponentOpacityActive`] = true;
        }, 330);
      }, 660);
    },
    startView() {
      this.transitionView('left', 'NextEvent');
      this.transitionView('right', 'Statistics');
    },
    beforeDraw() {
      this.transitionView('left', 'WaitingForDraw');
      const right = this.$store.getters.isResultsDataValid
        ? 'LastResultsView' : 'NextSameEvents';
      this.transitionView('right', right);
    },
    clearCallResultsInterval() {
      clearInterval(this.callResultsInterval);
    },
  },
  mounted() {
    setTimeout(() => {
      this.startView();
    }, 5000);
    EventBus.$on('remainingTime', (time) => {
      this.remainingTime = time;
    });
    EventBus.$on('startView', () => {
      this.startView();
    });
    EventBus.$on('beforeDraw', () => {
      this.$store.dispatch('setActiveCounter', false);
      this.beforeDraw();
      let sum = 0;
      EventBus.$emit('disabledActiveCounter', true);
      this.callResultsInterval = setInterval(async () => {
        if (!this.$store.getters.isVisualizationDrawEnabled) {
          this.clearCallResultsInterval();
        }
        sum += 1;
        if (sum === 70) {
          EventBus.$emit('stopIntervalsAndTimeouts');
          setTimeout(() => {
            this.transitionView('left');
            this.transitionView('right');
            EventBus.$emit('curtainDown', 'waitingForDraw');
          }, 1000);
        }
        if (sum === 80) {
          let timeout = 0;
          this.clearCallResultsInterval();
          // eslint-disable-next-line no-unused-vars
          const ready = await this.$store.dispatch('fetchResultsAndStatistics');
          if (this.$store.getters.isResultsDataValid) {
            EventBus.$emit('stopIntervalsAndTimeouts');
            this.$store.dispatch('setDrawInProgress', true);
            this.transitionView('left', 'LotteryDraw');
            this.transitionView('right', 'LotteryDrawSidebar');
          } else {
            this.startView();
            EventBus.$emit('changeTitle', 'drawDisplayTemporarilyNotAvailable');
            timeout = 5000;
          }
          workerPostMessage('startCounter', {
            drawTime: Object.keys(this.$store.getters.retailNextEvent).length
              ? this.$store.getters.retailNextEvent.eventStartTimeUTC - 60000 : null,
            otherNextTime: first(this.$store.getters.bettingOffer(0))?.eventStartTimeUTC - 60000,
          });
          this.$store.commit('PREPARE_RETAIL_DRAW_EVENT', this.$store.getters.retailNextEvent);

          setTimeout(() => {
            EventBus.$emit('curtainUp');
            if (!this.$store.getters.drawInProgress) {
              setTimeout(() => {
                EventBus.$emit('disabledActiveCounter', false);
              }, 5000);
            }
          }, timeout);
        }
      }, 1000);
    });
    EventBus.$on('changeStatistics', () => {
      this.transitionView('right', 'Statistics');
    });
    EventBus.$on('switchComponent', (event) => {
      if (this.$store.getters.drawInProgress) {
        return;
      }
      let { numb } = event;
      if (event.side === 'right' && event.numb === 2 && !this.$store.getters.isResultsDataValid) {
        numb = 3;
      }
      this.transitionView(event.side, this.viewConfig[event.side][numb]);
    });
    EventBus.$on('startCounter',
      () => {
        this.$store.dispatch('setActiveCounter', true);
      });
    EventBus.$on('startAgainEverything',
      () => {
        this.transitionView('left');
        this.transitionView('right');
        setTimeout(() => {
          this.$store.dispatch('setDrawInProgress', false);
          EventBus.$emit('curtainUp');
          setTimeout(() => {
            this.startView();
          }, 2000);
          setTimeout(() => {
            EventBus.$emit('disabledActiveCounter', false);
          }, 5000);
        }, 5000);
      });
  },
  beforeDestroy() {
    this.clearCallResultsInterval();
  },
};
</script>

<style lang="scss" scoped>
.comming-draws-wrapper {
  display: grid;
  grid-template-columns: 60.898% calc(100% - 60.898% - 24px);
  grid-column-gap: 24px;
  height: calc(100% - 50px);
  max-height: calc(100% - 50px);
  padding: 16px;
  position: relative;
  top: 50px;
  &.draw {
    grid-template-columns: 79.486% calc(100% - 79.486%);
    grid-column-gap: 0;
    @media (min-width: 1920px) {
      grid-template-columns: 80.5% calc(100% - 80.5%);
    }
    .comming-draws-next-event-wrapper,
    .comming-draws-sidebar-wrapper {
      padding: 0;
    }
  }
  &:not(&.draw) {
    > section {
      > div {
        padding: 16px;
        background: rgba(45, 45, 45, 0.2);
        backdrop-filter: blur(10.9683px);
        border-radius: 8px;
        box-shadow: 0 2px 8px rgb(0 0 0 / 50%);
        @media (min-width: 1920px) {
          padding: 24px;
        }
      }
    }
  }
  > section {
    &.left {
      > div {
        transform: translateX(-110%);
      }
    }
    &.right {
      > div {
        transform: translateX(+110%);
      }
    }
    > div {
      opacity: 0;
      transition: transform .66s cubic-bezier(.46,.08,.12,1),
      opacity .33s cubic-bezier(.46,.08,.12,1);
      &.active {
        transform: translateX(0px);
      }
      &.opacity {
        opacity: 1;
      }
    }
  }
  @media (min-width: 1920px) {
    grid-template-columns: 60.898% calc(100% - 60.898% - 33px);
    grid-column-gap: 33px;
    height: calc(100% - 80px);
    max-height: calc(100% - 80px);
    top: 80px;
  }
}
</style>
